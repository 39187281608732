

























































































// 订单头部筛选未完成
import { Component, Vue } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue"
import { RequestPaging } from "@/utils/util";
import DatePicker from "@/components/date-picker.vue"
import { apiRechargeRecord } from "@/api/marketing/recharge";
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsPagination,
        LsDialog,
        DatePicker,
        ExportData
    },
})
export default class GooRechargeRecord extends Vue {
    /** S Data **/
    $refs!: { paneTable: any }

    apiRechargeRecord = apiRechargeRecord

    SearchData = {
        nickname: "",
        pay_status: "",
        sn: "",
        start_time: "",
        end_time: "",
        type_time: ''
    };

    // 支付方式
    pay_way = [
        { name: "", label: "全部" },
        { name: "2", label: "微信支付" },
        { name: "3", label: "支付宝支付" },
    ];

    // 支付状态
    pay_status = [
        { value: "", label: "全部" },
        { value: "0", label: "未支付" },
        { value: "1", label: "已支付" },
    ];

    pager = new RequestPaging();

    /** E Data **/

    /** S Method **/

    // 获取充值记录数据
    getRecord(): void {
        this.pager.request({
            callback: apiRechargeRecord,
            params: {
                ...this.SearchData,
            },
        });
    }

    // 重置搜索领取记录
    resetSearchData() {
        Object.keys(this.SearchData).map((key) => {
            this.$set(this.SearchData, key, "");
        });
        this.getRecord()
    }

    /** E Method **/

    created() {
        this.getRecord();
    }
}
